<template>
  <div>
    <the-breadcrumbs></the-breadcrumbs>
    <page-content page-title="Neue Nummer">
      <emergency-number-form
        :number="number"
        submit-label="Neue Nummer anlegen"
        :form-submitted="saveNumber"
        mode="new"
      ></emergency-number-form>
    </page-content>
  </div>
</template>

<script>
import TheBreadcrumbs from "@/components/TheBreadcrumbs"
import PageContent from "@/components/PageContent"
import EmergencyNumberForm from "@/components/forms/EmergencyNumberForm"
import EmergencyNumbersRepository from "@/repositories/emergency_numbers_repository.js"

export default {
  metaInfo: {
    title: "Neue Nummer"
  },
  components: {
    TheBreadcrumbs,
    PageContent,
    EmergencyNumberForm
  },
  data() {
    return {
      number: {}
    }
  },
  async created() {},
  methods: {
    async saveNumber(number) {
      await EmergencyNumbersRepository.create(number)
      this.$router.push({ name: "EmergencyNumbersPage" })
    }
  }
}
</script>
